import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TablePaginationConfig } from 'antd/es/table'
import { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'

import { HttpMethods } from '@/common/constants/http-methods.constants'
import api from '@/common/helpers/api.helpers'
import { TableParamsType } from '@/common/interfaces/fancy-table.interfaces'
import { downloadFile } from '@/common/services/util-services'
import { buildPaginationQuery } from '@/common/utils/table.utils'

const fetchBookingTypes = createAsyncThunk('leases/fetchBookingTypes', async () => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/booking/types`)
  return response.data
})

const fetchLeases = createAsyncThunk(
  'leases/fetchLeases',
  async ({
    tableParams,
    filter,
    leaseType,
    search,
    signal,
  }: {
    tableParams: TableParamsType
    filter: string
    leaseType: string | number | undefined
    search?: string
    signal?: AbortSignal
  }) => {
    let query =
      buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases`, tableParams) +
      `&filter=${filter}&type=${leaseType}`

    if (search) {
      query += `&search=${search}`
    }

    const response = await api(HttpMethods.GET, query, undefined, undefined, signal)
    return response.data
  },
)
const fetchLeasesAnnounce = createAsyncThunk('leases/fetchLeasesAnnounce', async (date: string) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/announce`, { date })
  return response.data
})

const addAnnounce = createAsyncThunk('leases/addAnnounce', async (data: { message: string; doctor_ids: number[] }) => {
  const response = await api(HttpMethods.POST, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/announce`, data)
  return response.data
})

const fetchLeasesSimpleReport = createAsyncThunk(
  'leases/fetchLeasesSimpleReport',
  async ({
    tableParams,
    data,
  }: {
    tableParams: TablePaginationConfig
    data: { start_date: string; end_date: string; export: boolean }
  }) => {
    const config: AxiosRequestConfig = {
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'text/csv; charset=UTF-8',
      },
    }

    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/report`, tableParams),
      { ...data, export: Number(data.export) },
      data.export ? config : undefined,
    )

    if (data.export) {
      const startDate = dayjs(data.start_date).format('MM/DD/YYYY')
      const endDate = dayjs(data.end_date).format('MM/DD/YYYY')
      const fileName = `Bookings report ${startDate} - ${endDate}.csv`
      downloadFile(response.data, 'text/csv', fileName)
    }
    return response.data
  },
)

const fetchLeasesDetailedReport = createAsyncThunk(
  'leases/fetchLeasesDetailedReport',
  async ({
    tableParams,
    data,
  }: {
    tableParams: TablePaginationConfig
    data: { start_date: string; end_date: string; export: boolean }
  }) => {
    const config = {
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'text/csv; charset=UTF-8',
      },
    }

    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/detailed-report`, tableParams),
      { ...data, export: Number(data.export) },
      data.export ? (config as AxiosRequestConfig) : undefined,
    )

    if (data.export) {
      const startDate = dayjs(data.start_date).format('MM/DD/YYYY')
      const endDate = dayjs(data.end_date).format('MM/DD/YYYY')
      const fileName = `Bookings report ${startDate} - ${endDate}.csv`
      downloadFile(response.data, 'text/csv', fileName)
    }
    return response.data
  },
)
const fetchLeasesPromotionReport = createAsyncThunk(
  'leases/fetchLeasesPromotionReport',
  async ({ data }: { data: { start_date: string; end_date: string; export: boolean } }) => {
    const config = {
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'text/csv; charset=UTF-8',
      },
    }

    const response = await api(
      HttpMethods.GET,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/detailed-report-promotions`,
      { ...data, export: Number(data.export) },
      data.export ? (config as AxiosRequestConfig) : undefined,
    )

    if (data.export) {
      const startDate = dayjs(data.start_date).format('MM/DD/YYYY')
      const endDate = dayjs(data.end_date).format('MM/DD/YYYY')
      const fileName = `Promotion report ${startDate} - ${endDate}.csv`
      downloadFile(response.data, 'text/csv', fileName)
    }
    return response.data
  },
)

const downloadLeasesInvoice = createAsyncThunk(
  'leases/fetchLeasesInvoice',
  async ({
    lease_id,
    invoice_id,
    invoice_number,
  }: {
    lease_id: number | string
    invoice_id: number
    invoice_number: string
  }) => {
    const config = {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf',
      },
    }

    const response = await api(
      HttpMethods.GET,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${lease_id}/download/invoice/${invoice_id}`,
      null,
      config as AxiosRequestConfig,
    )

    downloadFile(response.data, 'application/pdf', `${invoice_number}.pdf`)
  },
)

const downloadLeasesContract = createAsyncThunk(
  'leases/downloadLeasesContract',
  async ({ lease_id, lease_name, doc_type }: { lease_id: number | string; lease_name: string; doc_type: string }) => {
    const config = {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf',
      },
    }

    const response = await api(
      HttpMethods.GET,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${lease_id}/download/${doc_type}`,
      null,
      config as AxiosRequestConfig,
    )

    downloadFile(response.data, 'application/pdf', `${lease_name}.pdf`)
  },
)

const fetchLeasesFromInterval = createAsyncThunk(
  'leases/fetchLeasesFromInterval',
  async (data: { start_date: string; end_date: string }) => {
    const response = await api(
      HttpMethods.GET,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/lease-days-from-interval`,
      data,
    )
    return response.data
  },
)

const fetchDuringLease = createAsyncThunk(
  'leases/fetchDuringLease',
  async ({ tableParams, id }: { tableParams: TablePaginationConfig; id: number }) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}/during-lease`, tableParams),
    )
    return response.data
  },
)

const fetchLease = createAsyncThunk('leases/fetchLease', async ({ id }: { id: string | number }) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}`)
  return response.data
})

const cancelLease = createAsyncThunk('leases/cancelLease', async ({ id }: { id: string | number }) => {
  const response = await api(HttpMethods.PUT, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}/cancel-lease`)
  return response.data
})

const completeLease = createAsyncThunk('leases/completeLease', async ({ id }: { id: string | number }) => {
  const response = await api(
    HttpMethods.PUT,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}/complete-lease`,
  )
  return response.data
})

const fetchLeaseDay = createAsyncThunk(
  'leases/fetchLeaseDay',
  async ({ id, day }: { id: string | number; day: string }) => {
    const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}/day/${day}`)
    return response.data
  },
)

const fetchLeaseCodes = createAsyncThunk('leases/fetchLeaseCodes', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}/codes`)
  return response.data
})

const fetchLeaseMedia = createAsyncThunk('leases/fetchLeaseMedia', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}/media`)
  return response.data
})

const fetchLeaseDocuments = createAsyncThunk('leases/fetchLeaseDocuments', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}/documents`)
  return response.data
})

const fetchLeaseInvoices = createAsyncThunk('leases/fetchLeaseInvoices', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}/invoices`)
  return response.data
})

const fetchLeaseDeposit = createAsyncThunk('leases/fetchLeaseDeposit', async (id: string | number) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}/deposit`)
  return response.data
})

const fetchLeaseDamages = createAsyncThunk(
  'leases/fetchLeaseDamages',
  async (payload: { id: string | number; date: string }) => {
    const response = await api(
      HttpMethods.GET,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${payload.id}/day/${payload.date}/damages`,
    )
    return response.data
  },
)

const fetchSiteRoomsFutureLeases = createAsyncThunk('rooms/fetchSiteRoomsFutureLeases', async (date: string) => {
  const response = await api(HttpMethods.GET, `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/rooms-type-leases`, {
    date,
  })
  return response.data
})

const fetchLeaseDamageList = createAsyncThunk(
  'leases/fetchLeaseDamageList',
  async (payload: { id: string | number; tableParams: TablePaginationConfig }) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(
        `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${payload.id}/damage-list`,
        payload.tableParams,
      ),
    )
    return response.data
  },
)

const fetchLeaseExtraChargesList = createAsyncThunk(
  'leases/fetchLeaseExtraChargesList',
  async (payload: { id: string | number; tableParams: TablePaginationConfig }) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(
        `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${payload.id}/extra-charge-list`,
        payload.tableParams,
      ),
    )
    return response.data
  },
)

const fetchLeaseDoctorsLeases = createAsyncThunk(
  'leases/fetchLeaseDoctorsLeases',
  async ({ id, tableParams }: { id: string | number; tableParams: TablePaginationConfig }) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/doctors/${id}/leases`, tableParams),
    )
    return response.data
  },
)

const fetchLeasePatients = createAsyncThunk(
  'leases/fetchLeasePatients',
  async ({ id, tableParams }: { id: string | number; tableParams: TablePaginationConfig }) => {
    const response = await api(
      HttpMethods.GET,
      buildPaginationQuery(`${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/${id}/patients`, tableParams),
    )
    return response.data
  },
)

const addDamage = createAsyncThunk('leases/addDamage', async (data: {}) => {
  const response = await api(
    HttpMethods.POST,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/deposit/add-damage`,
    data,
  )
  return response.data
})

const addExtraCharge = createAsyncThunk('leases/addExtraCharge', async (data: {}) => {
  const response = await api(
    HttpMethods.POST,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/deposit/add-extra-charge`,
    data,
  )
  return response.data
})

const addLeaseComment = createAsyncThunk(
  'leases/addLeaseComment',
  async (data: { lease_id: number; comment: string }) => {
    const response = await api(
      HttpMethods.POST,
      `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/deposit/add-comment`,
      data,
    )
    return response.data
  },
)

const processRefund = createAsyncThunk('leases/approveRefund', async (data: {}) => {
  const response = await api(
    HttpMethods.POST,
    `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/leases/deposit/approve-refund`,
    data,
  )
  return response.data
})

export const leasesService = {
  addDamage,
  addExtraCharge,
  fetchBookingTypes,
  fetchLeases,
  completeLease,
  fetchLease,
  cancelLease,
  fetchLeaseDay,
  fetchLeaseCodes,
  fetchLeaseMedia,
  fetchLeaseDeposit,
  processRefund,
  fetchLeaseDamages,
  fetchLeaseDamageList,
  fetchLeaseExtraChargesList,
  fetchLeaseDocuments,
  fetchLeasePatients,
  fetchLeaseDoctorsLeases,
  fetchDuringLease,
  fetchLeaseInvoices,
  addLeaseComment,
  fetchLeasesFromInterval,
  fetchSiteRoomsFutureLeases,
  fetchLeasesSimpleReport,
  fetchLeasesDetailedReport,
  fetchLeasesAnnounce,
  addAnnounce,
  downloadLeasesInvoice,
  downloadLeasesContract,
  fetchLeasesPromotionReport,
}
